<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker
                                        v-model="searchForm.datepicker"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions0"
                                        size="small"
                                        style="margin-right: 10px" @change="Search">
                                </el-date-picker>
                            </el-form-item>
                            <!--<el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..." @keyup.enter.native="SearchInputKeydown"></el-input>
                            </el-form-item>-->
                            <el-form-item>
                                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData"
                          stripe
                          height="calc(100% - 90px)"
                          style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="organizationName"
                                     label="机构名称">
                    </el-table-column>
                    <el-table-column prop="staffCount"
                                     label="员工账号数量" align="center">
                    </el-table-column>
                    <el-table-column prop="stayTime" label="浏览时长" align="center">
                        <template slot-scope="data">{{FormatSecond(data.row.stayTime)}}</template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import bread from "../../../components/common/breadcrumb"
import { STATS } from "../../../components/HospitalDomain/STATS";
import { StrToShotDate, FormatSecond } from '../../../components/common/Date';
    export default {
        name: "STATSOrganize",
        components: {
            bread
        },
        data(){
            var stats = new STATS(this.TokenClient, this.Services.Healthy);
            return{
                STATSDomain: stats,
                tableData: [],
                searchForm: {
                    statrTime: '',
                    endTime: '',
                    keyWord: '',
                    datepicker: ''
                },
                FormatSecond:FormatSecond,
                PageIndex: 1,
                PageTotal: 1,
                PageSize:15,
                DataTotal: 0,
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
                },
            }
        },
        mounted() {
            this.getList();
        },
        methods:{
            SearchInputKeydown(event) {
                if (event.keyCode == 13) {

                }
            },
            Search(event) {
                this.PageIndex = 1;
                this.getList();
            },
            ChangePage(pageIndex){
                this.PageIndex = pageIndex;
                this.getList();
            },
            getList(){
                var _this = this;
                var item = _this.searchForm;
                if( _this.searchForm.datepicker){
                    item.startTime = _this.searchForm.datepicker[0] +' 00:00:00';
                    item.endTime = _this.searchForm.datepicker[1] +' 23:59:59';
                } else { 
                    item.startTime = "";
                    item.endTime = "";
                }
                _this.STATSDomain.SubOrgBrowse(item.startTime, item.endTime, _this.PageIndex,
                    function (data) {
                        _this.tableData = data.data.results;
                        _this.PageIndex = data.data.pageIndex;
                        _this.PageSize=data.data.pageSize;
                        _this.PageTotal = data.data.pageCount;
                        _this.DataTotal = data.data.dataTotal;
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>

