/*
 * @Author: your name
 * @Date: 2021-06-09 16:46:18
 * @LastEditTime: 2021-06-15 17:23:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \MedicineDiagnosis.Management.Web\src\components\common\Date.js
 */
function DateStrFormat(str) {
    let date = new Date(str);
    // 注意js里面的getMonth是从0开始的
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    var second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}

function DateToStr(date) {
	var y = date.getFullYear()
	var m = date.getMonth() + 1
	m = m < 10 ? '0' + m : m
	var d = date.getDate()
	d = d < 10 ? '0' + d : d
	var h = date.getHours()
	h = h < 10 ? '0' + h : h
	var minute = date.getMinutes()
	minute = minute < 10 ? '0' + minute : minute
	var second = date.getSeconds()
	second = second < 10 ? '0' + second : second
	return y + '-' + m + '-' + d + 'T' + h + ':' + minute + ':' + second
}
//去掉时间T和毫秒
function StrToShotDate(string) {
	return string
		.replace(/T/g, ' ')
		.replace(/.[\d]{3}Z/, ' ')
		.slice(0, string.indexOf('.'))
}
//将秒转换为时分秒
function FormatSecond(value) {
	var theTime = parseInt(value) // 秒
	var middle = 0 // 分
	var hour = 0 // 小时

	if (theTime > 60) {
		middle = parseInt(theTime / 60)
		theTime = parseInt(theTime % 60)
		if (middle > 60) {
			hour = parseInt(middle / 60)
			middle = parseInt(middle % 60)
		}
	}
	var result = '' + parseInt(theTime) + '秒'
	if (middle > 0) {
		result = '' + parseInt(middle) + '分' + result
	}
	if (hour > 0) {
		result = '' + parseInt(hour) + '小时' + result
	}
	return result
}
export { DateStrFormat, DateToStr, StrToShotDate, FormatSecond }
