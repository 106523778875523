
let STATS = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 机构后台---下级机构学习时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.SubOrgBrowse = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/HospitalChildrensBrowse'
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 机构后台--员工学习时长统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.AllUserTime = function (startTime, endTime,keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/AllUserTime'
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }/**
     * 机构后台--员工学习时长统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.StatisticsTime = function (userId, pageIndex, success, error) {
        var types='disease,drug,material,herbal,food,acupoint,meridian,video'
        var url = ServiceBaseAddress + `/api/StatisticsTime/Detail/${userId}/${types}`
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 机构首页---下级机构学习时间top10
     * @param success
     * @param error
     * @constructor
     */
    this.SubOrgBrowseTop = function (success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/HospitalChildrensBrowseTop10'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 机构首页---所有员工月学习平均时长
     * @param success
     * @param error
     * @constructor
     */
    this.AllUserMonth = function (year,success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/AllUserMonthAverage/'+year
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 机构首页---员工学习时长top10
     * @param success
     * @param error
     * @constructor
     */
    this.AllUserTop = function (success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/AllUserTop10'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 总后台--自营商品统计列表
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.CommodityStatisticsList = function (type,pageIndex, keyWord,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Commodity/Org/'+type;

        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += `/%20`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 后台--商品统计详情
     * @param commodityId
     * @param success
     * @param error
     * @constructor
     */
    this.CommodityStatisticsNum = function (commodityId, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Commodity/' + commodityId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 后台--获取指定商品销售明细
     * @param commodityId
     * @param success
     * @param error
     * @constructor
     */
    this.SalesDetails = function (commodityId,pageIndex,keyWord,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Commodity/SalesDetails/' + commodityId;
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += `/%20`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 总后台--订单统计列表
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.OrderStatisticsList = function (type,pageIndex, keyWord,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Order/Org/'+type;

        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += `/%20`;
        }
        if (pageSize) { 
            url += `/${pageSize}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 总后台--视频销售统计
     * @param {*} startTime 
     * @param {*} endTime 
     * @param {*} keyWord 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
    this.VideoSales = function (startTime, endTime, keyWord, pageIndex, pageSize,success, error) {
        var url = ServiceBaseAddress + '/api/Video/VideoSalesOrg';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += `/%20`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export { STATS }
